//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$primary: #4c63d5; // Changing primary color
.pb-60 {
    padding-bottom: 60rem;
}
.ql-container .ql-editor {
    height: 20rem;
}
// Modal XS
.modal-xlg {
    max-width: 1100px;
}
// Modal XS
.modal-xlg2 {
    max-width: 1300px;
}
body.modal-open {
    overflow: auto;
}

body.modal-open[style] {
    padding-right: 0px !important;
}
.p-concierge {
    padding: 0.5rem !important;
}